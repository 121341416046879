<template>
  <v-container fluid>
    <v-card>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1"><v-icon size="40">mdi-alarm-multiple</v-icon> PHIẾU TĂNG
            CA</v-list-item-title>
          <v-list-item-subtitle>Danh sách phiếu báo làm thêm giờ</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <br />
    <v-card class="mb-1">
      <v-card-title class="pa-6 pb-4">
        <div style="width: 100%" class="mr-4">
          <v-row>
            <v-col cols="3">
              <v-select v-model="dia_diem_id" item-text="name" item-value="id" :items="diaDiems"
                placeholder="Địa điểm - khu vực" hide-details clearable></v-select></v-col>
            <v-col cols="2">
              <v-autocomplete v-model="nhom_to_id" item-text="name" item-value="id" :items="nhomTos"
                placeholder="Nhóm tổ" hide-details :filter="fullTextFilter" clearable></v-autocomplete></v-col>
            <v-col cols="3">
              <v-menu ref="menu" v-model="showDate" :close-on-content-click="false" :return-value.sync="date"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateRangeText" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                    clearable placeholder="Chọn từ ngày đến ngày"></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showDate = false">
                    Hủy
                  </v-btn>
                  <v-btn text color="primary" @click="changeDate()" :disabled="!this.date || this.date.length != 2"> OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-btn tile color="success" @click="getData" class="mt-3">
                <v-icon left> mdi-magnify </v-icon>
                <div class="btn-text">Tìm kiếm</div>
              </v-btn>
            </v-col>
            <v-col class="btn-text" cols="1">
            </v-col>
            <v-col class="btn-add" cols="1">
            </v-col>
            <v-col cols="1">
              <v-btn tile color="success" @click="create" class="mt-3" title="Thên mới phiếu tăng ca">
                <v-icon left> mdi-plus </v-icon>
                <div class="btn-text">Thêm mới</div>
              </v-btn>
            </v-col>
            <!-- <v-col cols="5">
              <v-text-field
                append-icon="mdi-magnify"
                v-model="search"
                label="Tìm kiếm"
                clearable
                single-line
                hide-details
              ></v-text-field
            ></v-col> -->
          </v-row>
        </div>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :page.sync="page" :items-per-page="itemsPerPage"
        hide-default-footer :loading="loading" class="elevation-1" loading-text="Đang tải dữ liệu ...">
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar color="indigo" size="28">
            <img v-if="item.nguoi_gui.url_image" :src="imageEndpoint + item.nguoi_gui.url_image" alt="ManhLe" />
            <span style="color: white" v-else-if="item && item.nguoi_gui.name">{{
              item.name.charAt(0).toUpperCase()
            }}</span>
            <v-icon v-else dark>mdi-account</v-icon>
          </v-avatar>
          <span class="ml-3" style="font-size: 16px">{{
            item.nguoi_gui.name
          }}</span>
        </template>
        <template v-slot:[`item.thoi_gian`]="{ item }">
          {{ formatDateTime(item.bat_dau) }} -
          {{ formatDateTime(item.ket_thuc) }}
        </template>
        <template v-slot:[`item.trang_thai`]="{ item }">
          <div>
            <div v-if="item.trang_thai == 'CHAP_NHAN'" style="color: green">Đã duyệt <b>đề nghị</b> tăng ca</div>
            <div v-if="item.trang_thai == 'TU_CHOI'" style="color: pink">Từ chối <b>đề nghị</b> tăng ca</div>
            <div v-if="item.trang_thai_xac_nhan == 'CHAP_NHAN'" style="color: green">Đã duyệt <b>kết quả</b> tăng ca
            </div>
            <div v-if="item.trang_thai_xac_nhan == 'TU_CHOI'" style="color: pink">Từ chối <b>kết quả</b> tăng ca</div>

            <div v-if="(item.trang_thai == 'CHO_DUYET' && item.trang_thai_xac_nhan == 'CHO_DUYET')" style="color: blue">
              <b>Chờ duyệt</b>
            </div>
          </div>
        </template>
        <!-- <template v-slot:[`item.nguoi_duyet`]="{ item }">
          <div v-if="item.nguoi_duyet">
            <v-avatar color="indigo" size="28">
              <img
                v-if="item.nguoi_duyet && item.nguoi_duyet.url_image"
                :src="imageEndpoint + item.nguoi_duyet.url_image"
                alt="ManhLe"
              />
              <span
                style="color: white"
                v-else-if="item.nguoi_duyet && item.nguoi_duyet.name"
                >{{ item.name.charAt(0).toUpperCase() }}</span
              >
            </v-avatar>
            <span
              v-if="item.nguoi_duyet"
              class="ml-3"
              style="font-size: 16px"
              >{{ item.nguoi_duyet.name }}</span
            >
          </div>
        </template> -->

        <template v-slot:[`item.ly_do_tang_ca`]="{ item }">
          <div>
            <div v-if="item.ly_do_tang_ca && item.ly_do_tang_ca.length < 30">{{ item.ly_do_tang_ca }}</div>
            <div v-else-if="item.ly_do_tang_ca && item.ly_do_tang_ca.length > 30">{{
              item.ly_do_tang_ca.substring(0, 30)
                + '...'
            }}</div>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="textColor">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$router.push('/chamcong/tangca/' + item.id)">
                <v-list-item-title>
                  <v-icon>mdi-eye</v-icon> Chi tiết
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="edit(item)">
                <v-list-item-title>
                  <v-icon>mdi-pencil</v-icon>
                  Cập nhật</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>
                  <v-icon>mdi-delete</v-icon>
                  Xóa</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <div class="pt-2">
      <v-pagination v-model="page" :length="pageCount" @input="changePage" :total-visible="10">></v-pagination>
    </div>
    <Show ref="chitiet" @on-done="getData" />
    <create-edit ref="tangca" @on-done="getData"></create-edit>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Bạn có chắc muốn xóa phiếu tăng ca này không?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteConfirm">
            Xóa
          </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">
            Hủy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { debounce } from "lodash";
import { getTangCa, deleteTangCa } from "@/api/tangca";
import { getDiaDiem } from "@/api/diadiem";
import Show from "./show";
import CreateEdit from "./create-edit";
import { fullTextFilter } from "../../../utils/stringHelper";
import { getNhomToTrucThuoc } from "@/api/nhomto";

export default {
  components: { Show, CreateEdit },
  data() {
    return {
      page: 1,
      fullTextFilter,
      nhomTos: [],
      pageCount: 1,
      date: [],
      nhom_to_id: null,
      itemsPerPage: 10,
      totalDesserts: 0,
      tableData: [],
      showDate: false,
      btnLoading: false,
      loading: false,
      search: "",
      imageEndpoint: process.env.VUE_APP_BASE,
      diaDiems: [],
      dia_diem_id: null,
      dateRangeText: "",
      itemdelete: null,
      dialog: false,
      headers: [
        { text: "Tổ trưởng", value: "to_truong.name", sortable: false },
        // {
        //   text: "Nhóm tổ",
        //   align: "start",
        //   sortable: false,
        //   value: "nhom_to",
        // },
        {
          text: "Địa điểm",
          align: "start",
          sortable: false,
          value: "dia_diem.name",
        },
        {
          text: "Thời gian tăng ca",
          align: "start",
          sortable: false,
          value: "thoi_gian",
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "trang_thai",
        },
        // {
        //   text: "Người duyệt",
        //   align: "start",
        //   sortable: false,
        //   value: "nhan_vien_kinh_doanh.name",
        // },
        {
          text: "Lý do tăng ca",
          align: "start",
          sortable: false,
          value: "ly_do_tang_ca",
        },
        {
          text: "Chi tiết",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getDanhSachDiaDiem();
    this.getNhomTo()
  },
  watch: {
    date(val) {
      if (val && val.length == 2) {
        if (new Date(this.date[0]) > new Date(this.date[1])) {
          let temp = this.date[0];
          this.date[0] = this.date[1];
          this.date[1] = temp;
        }
        let newDate = [...this.date];
        newDate[0] = this.formatDate(newDate[0]);
        newDate[1] = this.formatDate(newDate[1]);
        this.dateRangeText = newDate.join(" - ");
      }
    },
    dateRangeText(val) {
      if (val == null) {
        this.date = null;
        this.getData();
      }
    },
    search: debounce(async function (val) {
      this.loading = true;
      let data = await getTangCa({
        page: this.page,
        perPage: this.itemsPerPage,
        search: val,
        dia_diem_id: this.dia_diem_id,
        date:
          this.date && this.date.length
            ? [new Date(this.date[0]), new Date(this.date[1])]
            : null,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.last_page;
    }, 300),
  },
  methods: {
    async getData() {
      this.loading = true;
      let data = await getTangCa({
        page: this.page,
        perPage: this.itemsPerPage,
        dia_diem_id: this.dia_diem_id,
        search: this.search,
        to_id: this.nhom_to_id,
        date:
          this.date && this.date.length
            ? [new Date(this.date[0]), new Date(this.date[1])]
            : null,
      });
      this.tableData = data.data;
      this.loading = false;
      this.pageCount = data.last_page;
    },
    async getNhomTo() {
      let data = await getNhomToTrucThuoc({
        phong_ban_id: 2,
      });
      this.nhomTos = data;
    },

    async getDanhSachDiaDiem() {
      let data = await getDiaDiem({
        perPage: 9999,
      });
      this.diaDiems = data.data;
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    formatDateTime(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let gio =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let phut =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return (
          gio +
          "h " +
          phut +
          " Ngày " +
          ngay +
          "/" +
          thang +
          "/" +
          date.getFullYear()
        );
      } catch (error) {
        return "";
      }
    },
    changeDate() {
      this.$refs.menu.save(this.date);
      this.getData();
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
    showChiTiet(data) {
      this.$refs.chitiet.showFormEdit(data);
    },
    // xoaLichNghi(data) {
    //   this.$confirmBox.show({
    //     title: "Xóa lịch nghỉ",
    //     width: 480,
    //     body:
    //       "Bạn có chắc chắn muốn xóa lịch đăng ký nghỉ của nhân viên:  " +
    //       "<strong>" +
    //       data.nguoi_gui.name +
    //       " ?" +
    //       "</strong><br>" +
    //       "Từ ngày: " +
    //       this.formatDate(data.bat_dau) +
    //       " đến ngày: " +
    //       this.formatDate(data.ket_thuc),
    //     action: () => xoaTangCa({ lich_nghi_id: data.id }),
    //     onDone: this.getData,
    //   });
    // },
    create() {
      this.$refs.tangca.showFormAdd();
    },
    edit(item) {
      this.$refs.tangca.showFormEdit(item);
    },
    deleteItem(item) {
      this.dialog = true
      this.itemdelete = item
    },
    async deleteConfirm() {
      try {
        await deleteTangCa(this.itemdelete.id)
        this.dialog = false;
        this.$emit("on-done");
        this.$toast.info("Xóa thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.getData()
      } catch (error) {
        this.$toast.error("Xoá không thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.dialog = false;
      }
    }
  },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .btn-add {
    display: none;
  }

  .btn-text {
    display: none;
  }
}

@media only screen and (max-width: 1500px) {
  .btn-add {
    display: none;
  }

  /* .btn-text {
    display: none;
  } */
}

@media only screen and (max-width: 1200px) {
  .btn-add {
    display: block;
  }

  .btn-text {
    display: none;
  }
}
</style>
