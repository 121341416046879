<template>
  <v-dialog v-model="show" persistent width="900">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT PHIẾU TĂNG CA" : "THÊM PHIẾU TĂNG CA"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-row>
                <v-col cols="6">
                  <div class="label-form">Lý do tăng ca</div>
                  <v-text-field v-model="form.ly_do_tang_ca"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Thời gian bắt đầu</div>
                  <v-text-field type="datetime-local" v-model="form.bat_dau"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Bộ phận đề nghị tăng ca</div>
                  <v-text-field v-model="form.bo_phan_de_nghi_tang_ca"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Thời gian kết thúc</div>
                  <v-text-field type="datetime-local" v-model="form.ket_thuc"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Số người tham gia</div>
                  <v-text-field type="number" v-model="form.so_nguoi_tham_gia"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Người đề nghị</div>
                  <v-autocomplete
                    v-model="form.user_to_truong_id"
                    prepend-inner-icon="mdi-account"
                    :items="people"
                    chips
                    placeholder="Nhân viên"
                    item-text="name"
                    item-value="user_id"
                    :filter="fullTextFilter"
                    clearable
                    :rules="nameRules"
                  >
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected">
                        <v-avatar left color="indigo">
                          <img
                            v-if="data.item.url_image"
                            :src="imageEndpoint + data.item.url_image"
                            alt="ManhLe"
                          />
                          <span
                            style="color: white"
                            v-else-if="data.item && data.item.name"
                            >{{ data.item.name.charAt(0).toUpperCase() }}</span
                          >
                          <v-icon v-else dark>mdi-account</v-icon>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar color="indigo">
                          <img
                            v-if="data.item.url_image"
                            :src="imageEndpoint + data.item.url_image"
                            alt="ManhLe"
                          />
                          <span
                            style="color: white"
                            v-else-if="data.item && data.item.name"
                            >{{ data.item.name.charAt(0).toUpperCase() }}</span
                          >
                          <v-icon v-else dark>mdi-account</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >{{ data.item.email }} -
                            {{
                              data.item.so_dien_thoai
                                ? "SĐT: " + data.item.so_dien_thoai
                                : ""
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <div class="label-form">Địa điểm</div>
                  <v-autocomplete
                    v-model="form.dia_diem_id"
                    :items="diaDiems"
                    chips
                    placeholder="Địa điểm"
                    item-text="name"
                    item-value="id"
                    :filter="fullTextFilter"
                    clearable
                    :rules="diadiemRules"
                  >
                  </v-autocomplete>
                </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-8 pb-3">
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="themMoi"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="capNhat"
          v-else
        >
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { addTangCa, editTangCa } from "@/api/tangca";
import { fullTextFilter } from "../../../utils/stringHelper";
import { getNhanVien } from "@/api/nhanvien";
import { getDiaDiem } from "@/api/diadiem";


export default {
  data: () => ({
    imageEndpoint: process.env.VUE_APP_BASE,
    show: false,
    edit: false,
    fullTextFilter,
    dates: [],
    people: [],
    btnLoading: false,
    form: {
      user_to_truong_id: null,
        bat_dau: null,
        ket_thuc: null,
        ly_do_tang_ca: null,
        bo_phan_de_nghi_tang_ca: null,
        so_nguoi_tham_gia: null,
    },
    diaDiems:[],
    nameRules: [(v) => !!v || "Nhân viên không thể bỏ trống"],
    diadiemRules: [(v) => !!v || "Địa điểm không thể bỏ trống"],
  }),
  mounted() {
    this.getListNhanVien();
    this.getDanhSachDiaDiem();
  },
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        user_to_truong_id: null,
        bat_dau: null,
        ket_thuc: null,
        ly_do_tang_ca: null,
        bo_phan_de_nghi_tang_ca: null,
        so_nguoi_tham_gia: null,
        dia_diem_id: null
      };
      this.dates = [];
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },

    async getListNhanVien() {
      let data = await getNhanVien({
        perPage: 999,
      });
      this.people = data.data.filter(e=>e.chuc_vu=="Tổ trưởng");
      // console.log(this.people)
    },
    async getDanhSachDiaDiem() {
      let data = await getDiaDiem({
        perPage: 9999,
      });
      this.diaDiems = data.data;
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = {
        id: data.id,
        user_to_truong_id: data.user_to_truong_id,
        bat_dau: data.bat_dau,
        ket_thuc: data.ket_thuc,
        ly_do_tang_ca: data.ly_do_tang_ca,
        bo_phan_de_nghi_tang_ca: data.bo_phan_de_nghi_tang_ca,
        so_nguoi_tham_gia: data.so_nguoi_tham_gia,
        dia_diem_id: data.dia_diem_id
      };
      this.getListNhanVien();
      this.getDanhSachDiaDiem();
    },

    async themMoi() {
      this.$refs.form.validate();
      if (!this.form.bat_dau || !this.form.ket_thuc) {
        this.$toast.warning("Hãy chọn thời gian tăng ca", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await addTangCa(this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Thêm phiếu tăng ca thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async capNhat() {
      this.$refs.form.validate();
      if (!this.form.bat_dau || !this.form.ket_thuc) {
        this.$toast.warning("Hãy chọn thời gian tăng ca", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await editTangCa(this.form.id, this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
<style>
/* .value-form{
  padding: 0px !important;
  margin: 0px !important;
} */
.col{
  padding: 0px 30px !important;
  /* padding-right: 20px !important; */
}
</style>
