var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-alarm-multiple")]),_vm._v(" PHIẾU TĂNG CA")],1),_c('v-list-item-subtitle',[_vm._v("Danh sách phiếu báo làm thêm giờ")])],1)],1)],1),_c('br'),_c('v-card',{staticClass:"mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-4"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.diaDiems,"placeholder":"Địa điểm - khu vực","hide-details":"","clearable":""},model:{value:(_vm.dia_diem_id),callback:function ($$v) {_vm.dia_diem_id=$$v},expression:"dia_diem_id"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"id","items":_vm.nhomTos,"placeholder":"Nhóm tổ","hide-details":"","filter":_vm.fullTextFilter,"clearable":""},model:{value:(_vm.nhom_to_id),callback:function ($$v) {_vm.nhom_to_id=$$v},expression:"nhom_to_id"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","clearable":"","placeholder":"Chọn từ ngày đến ngày"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showDate = false}}},[_vm._v(" Hủy ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!this.date || this.date.length != 2},on:{"click":function($event){return _vm.changeDate()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"tile":"","color":"success"},on:{"click":_vm.getData}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_c('div',{staticClass:"btn-text"},[_vm._v("Tìm kiếm")])],1)],1),_c('v-col',{staticClass:"btn-text",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"btn-add",attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"tile":"","color":"success","title":"Thên mới phiếu tăng ca"},on:{"click":_vm.create}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_c('div',{staticClass:"btn-text"},[_vm._v("Thêm mới")])],1)],1)],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.nguoi_gui.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.nguoi_gui.url_image,"alt":"ManhLe"}}):(item && item.nguoi_gui.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.name.charAt(0).toUpperCase()))]):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.nguoi_gui.name))])]}},{key:"item.thoi_gian",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.bat_dau))+" - "+_vm._s(_vm.formatDateTime(item.ket_thuc))+" ")]}},{key:"item.trang_thai",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.trang_thai == 'CHAP_NHAN')?_c('div',{staticStyle:{"color":"green"}},[_vm._v("Đã duyệt "),_c('b',[_vm._v("đề nghị")]),_vm._v(" tăng ca")]):_vm._e(),(item.trang_thai == 'TU_CHOI')?_c('div',{staticStyle:{"color":"pink"}},[_vm._v("Từ chối "),_c('b',[_vm._v("đề nghị")]),_vm._v(" tăng ca")]):_vm._e(),(item.trang_thai_xac_nhan == 'CHAP_NHAN')?_c('div',{staticStyle:{"color":"green"}},[_vm._v("Đã duyệt "),_c('b',[_vm._v("kết quả")]),_vm._v(" tăng ca ")]):_vm._e(),(item.trang_thai_xac_nhan == 'TU_CHOI')?_c('div',{staticStyle:{"color":"pink"}},[_vm._v("Từ chối "),_c('b',[_vm._v("kết quả")]),_vm._v(" tăng ca")]):_vm._e(),((item.trang_thai == 'CHO_DUYET' && item.trang_thai_xac_nhan == 'CHO_DUYET'))?_c('div',{staticStyle:{"color":"blue"}},[_c('b',[_vm._v("Chờ duyệt")])]):_vm._e()])]}},{key:"item.ly_do_tang_ca",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.ly_do_tang_ca && item.ly_do_tang_ca.length < 30)?_c('div',[_vm._v(_vm._s(item.ly_do_tang_ca))]):(item.ly_do_tang_ca && item.ly_do_tang_ca.length > 30)?_c('div',[_vm._v(_vm._s(item.ly_do_tang_ca.substring(0, 30) + '...'))]):_vm._e()])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/chamcong/tangca/' + item.id)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" Chi tiết ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Cập nhật")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Xóa")],1)],1)],1)],1)]}}],null,true)})],1),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1),_c('Show',{ref:"chitiet",on:{"on-done":_vm.getData}}),_c('create-edit',{ref:"tangca",on:{"on-done":_vm.getData}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Bạn có chắc muốn xóa phiếu tăng ca này không? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteConfirm}},[_vm._v(" Xóa ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Hủy ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }